// src/services/backButtonService.ts
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

class BackButtonService {
  private static instance: BackButtonService;
  private navigate: any;

  // Singleton pattern to ensure only one instance of the service
  private constructor(navigate: any) {
    this.navigate = navigate;
  }

  // Get the singleton instance of the service
  public static getInstance(navigate: any): BackButtonService {
    if (!BackButtonService.instance) {
      BackButtonService.instance = new BackButtonService(navigate);
    }
    return BackButtonService.instance;
  }

  // Handle hardware back button (on mobile devices or PWA)
  public handleHardwareBackButton() {
    console.log('Hardware back button pressed');
    this.navigate(-1); // Navigate back in the history stack
  }

  // Handle custom back navigation for manual back button (UI-based)
  public handleManualBackButton() {
    console.log('Manual back button pressed');
    this.navigate(-1); // Navigate back in the history stack
  }
}

// Hook to integrate with React component lifecycle
export const useBackButtonService = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const backButtonService = BackButtonService.getInstance(navigate);

    // Listen for hardware back button (for PWA and mobile environments)
    const handlePopState = () => {
      backButtonService.handleHardwareBackButton();
    };

    window.addEventListener('popstate', handlePopState);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  return {
    handleManualBackButton: () => BackButtonService.getInstance(navigate).handleManualBackButton(),
  };
};
