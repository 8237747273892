import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import moment from "moment";
import {
  DownloadDashboardEmployeeResponse,
  DownloadDashboardSampleFileParam,
  FilterParam,
  ProductTracking,
  ProductTrackingResponse,
  ProductWiseEmployeeList,
  DocumentResponse,
  DocumentInfoParam,
  CreateProductInterestStatResponse,
  CreateProductUsageStatResponse,
  ProductStatRequestBody
} from "types/dashboard";
import { productApi } from "./product";
import captureSentryError from "helper/captureSentryError";

export const DASHBOARD_API_REDUCER_KEY = "dashboardApi";

export const dashboardApi = createApi({
  reducerPath: DASHBOARD_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    getProductWiseEmployeeList: builder.query<
      ProductWiseEmployeeList,
      Partial<FilterParam>
    >({
      query: (args: FilterParam) => {
        const {
          StartDate = null,
          EndDate = null,
          EmployerID = "",
          RoleID = "",
          ProductID = [],
        } = args;
        const body = {
          StartDate: StartDate === null ? new Date() : StartDate,
          EndDate: EndDate === null ? new Date() : EndDate,
          EmployerID,
          RoleID,
          ProductID,
        };
        return {
          url: ApiConstant.DASHBOARD_EMPLOYEE_LISTING,
          method: "POST",
          body: { ...body },
        };
      },
      providesTags: ["Dashboard"],
    }),
    getProductUsageStat: builder.query<
      CreateProductUsageStatResponse,
      ProductStatRequestBody
    >({
      query: (args: ProductStatRequestBody) => {
        const {
          StartDate = null,
          EndDate = null,
          EmployerID = "",
          RoleID = "",
          ProductID = [],
        } = args;
        const body = {
          StartDate: StartDate === null ? new Date() : StartDate,
          EndDate: EndDate === null ? new Date() : EndDate,
          EmployerID,
          RoleID,
          ProductID,
        };
        return {
          url: ApiConstant.DASHBOARD_PRODUCT_USAGE,
          method: "POST",
          body: { ...body },
        };
      }
    }),
    getProductInterestStat: builder.query<
      CreateProductInterestStatResponse,
      ProductStatRequestBody
    >({
      query: (args: ProductStatRequestBody) => {
        const {
          StartDate = null,
          EndDate = null,
          EmployerID = "",
          RoleID = "",
          ProductID = [],
        } = args;
        const body = {
          StartDate: StartDate === null ? new Date() : StartDate,
          EndDate: EndDate === null ? new Date() : EndDate,
          EmployerID,
          RoleID,
          ProductID,
        };
        return {
          url: ApiConstant.DASHBOARD_PRODUCT_INTEREST,
          method: "POST",
          body: { ...body },
        };
      }
    }),
    getAllProductWiseEmployeeList: builder.query<
      ProductWiseEmployeeList,
      Partial<FilterParam>
    >({
      query: (args: FilterParam) => {
        const {
          pageNo = 1,
          size = 30,
          EmployerID = "",
          RoleID = "",
          StartDate = null,
          EndDate = null,
          searchText = "",
          ProductID = [],
          sortColumn = "",
          sortOrder = "",
        } = args;
        const body = {
          pageNo,
          size,
          EmployerID,
          RoleID,
          StartDate,
          EndDate,
          searchText,
          ProductID,
          sortColumn,
          sortOrder,
        };
        return {
          url: ApiConstant.DASHBOARD_PRODUCTWISE_EMPLOYEE_LISTING,
          method: "POST",
          body: { ...body },
        };
      },
      providesTags: ["Dashboard"],
    }),
    showProductInterest: builder.mutation<
      ProductTrackingResponse,
      Partial<ProductTracking>
    >({
      query(body) {
        const { RoleID, ...rest } = body
        return {
          url: `${ApiConstant.SHOW_PRODUCT_INTEREST}`,
          method: "POST",
          body: rest,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data,
      invalidatesTags: ["Dashboard"],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            productApi.util.updateQueryData("getAllProducts", { EmployerID: body.EmployerID, RoleID: body.RoleID }, (draft) => {
              if (!body.IsLearnMore) {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ProductId === body.ProductID
                );
                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.CanShowInterest = false
                  draft.data[objIndex] = info;
                }
              }
            })
          )
        } catch (error: any) {
          if (error?.error?.statusCode === 401) {
            window.location.href = '/login'
            return;
          }

          captureSentryError(error)
          console.log("Error", error);
        }
      },
    }),
    downloadPrivacyPolicy: builder.mutation<
      DocumentResponse,
      DocumentInfoParam
    >({
      query: (body: DocumentInfoParam) => {
        return {
          url: ApiConstant.DOWNLOAD_PRIVACY_DOCUMENT,
          method: "POST",
          body,
        };
      },
    }),
    downloadSampleDashboardEmployeeFile: builder.query<
      DownloadDashboardEmployeeResponse,
      Partial<DownloadDashboardSampleFileParam>
    >({
      query: (body: DownloadDashboardSampleFileParam) => {
        return {
          url: ApiConstant.DOWNLOAD_DASHBOARD_SAMPLE_FILE,
          method: "POST",
          body,
        };
      },
      providesTags: ["Dashboard"],
    }),
  }),
});

export const {
  useGetProductWiseEmployeeListQuery,
  useGetAllProductWiseEmployeeListQuery,
  useShowProductInterestMutation,
  useLazyDownloadSampleDashboardEmployeeFileQuery,
  useDownloadPrivacyPolicyMutation,
  useGetProductUsageStatQuery,
  useGetProductInterestStatQuery
} = dashboardApi;
