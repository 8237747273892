import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import menu from "./menu";
import snackbar from "./snackbar";
import cartReducer from "./cart";
import filter from "./filter";
import { profileApi } from "./profile";
import { productApi } from "./product";
import { employerAdminProfile } from "./employerAdminProfile";
import auth from "./login";
import privateTempPlan from "./privateTempPlan";
import stepper from "./stepper";
import { orgRolesApi } from "./roles";
import { employeeApi } from "./employee";
import { adminMyprofileApi } from "./adminMyProfile";
import { dashboardApi } from "./dashboard";
import { webinarApi } from './webinar'
import { financialHealthCheckApi } from "./financialHealthCheck";
import { seminarApi } from "./seminar";
import { privatePensionApi } from "./privatePension";

const reducers = combineReducers({
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "mantis-ts-",
    },
    cartReducer
  ),
  filter,
  auth,
  privateTempPlan,
  stepper,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [orgRolesApi.reducerPath]: orgRolesApi.reducer,
  [employerAdminProfile.reducerPath]: employerAdminProfile.reducer,
  [adminMyprofileApi.reducerPath]: adminMyprofileApi.reducer,
  [webinarApi.reducerPath]: webinarApi.reducer,
  [financialHealthCheckApi.reducerPath]: financialHealthCheckApi.reducer,
  [seminarApi.reducerPath]: seminarApi.reducer,
  [privatePensionApi.reducerPath]: privatePensionApi.reducer,
});

export default reducers;
