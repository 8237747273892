import axios from "axios";

const axiosServices = axios.create({
  baseURL: "https://api.getAddress.io",
});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      !window.location.href.includes("/login")
    ) {
      window.location.href = "/login";
    }
    return Promise.reject(
      (error?.response && error?.response.data) || "Wrong Services"
    );
  }
);

export default axiosServices;
