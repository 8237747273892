import { EMPLOYEE_APP_DEFAULT_PATH, WEBINAR_PATH } from "config"
import { getCookies } from "helper/cookies"
import { store } from "store"
import { isEmpty } from "lodash"
import InvestmentAdviceIcon from "../assets/images/investment-advice-icon.svg"
import SavingsInvestmentIcon from "../assets/images/savings-investment-icon.svg"
import ProtectionInsuranceIcon from "../assets/images/protection-insurance-icon.svg"
import WillServiceIcon from "../assets/images/will-services-icon.svg"
import MortgageIcon from "../assets/images/mortgage-services-icon.svg"
import MoneyManagementIcon from "../assets/images/money-management-icon.svg"
import RetirementBenefitIcon from "../assets/images/retirement-advice-icon.svg"

 export const getPathFromInternalLink = (redirectedURl: string) => {
    let path = ''
    if (redirectedURl.includes("/employee/homeavc")) {
        path = 'home'
    } else if (redirectedURl.includes("/employee/manageavc")) {
        path = 'manage_my_shared_cost_avc'
    } else if (redirectedURl.includes("/employee/my-investment-advice")) {
        path = 'my_investment_advice'
    } else if (redirectedURl.includes("/employee/new-app-form")) {
        path = 'new_application'
    } else if (redirectedURl.includes("/employee/individualmeeting")) {
        path = 'calendly'
    }
    return path
}

function dynamicallyCreateAnchorAndNavigate(url: string) {
    let anchorElement = document.createElement('a');
    anchorElement.href = url;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
}

function openInNewTab(url: string) {
    dynamicallyCreateAnchorAndNavigate(url);
}

export const navigateToInternalLink = (path: string) => {
    const employeeDetails = getCookies("EmployeeDetails") ?? "";
    const parsedEmployeeDetails = employeeDetails;
    const employerSubDomain = parsedEmployeeDetails?.SubDomain;
    const client_id = process.env.REACT_APP_SILENT_LOGIN_CLIENT_ID;
    const callback = employerSubDomain ? `https://${employerSubDomain}${process.env.REACT_APP_AUTH_DEFAULT_CALLBACK}` : process.env.REACT_APP_AUTH_CALLBACK;
    const AvcAmount = localStorage.getItem("AvcAmount")
    const redirect_uri = `${callback}?path=${path}`;
    const domain = process.env.REACT_APP_AUTH_DOMAIN;
    const authUrl = `https://${domain}/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=openid profile email&prompt=none${AvcAmount ? `&state=avc_amount=${AvcAmount}`: '' }`;
    openInNewTab(authUrl)
    localStorage.removeItem('baseURl')
    localStorage.removeItem('AvcAmount')
}

const navigateToCalendly = () => {
    const auth = store.getState().auth
    window.open(
        `https://calendly.com/avc-wise-individual-meetings/avc-wise-individual-meeting?name=${auth.FirstName
        } ${auth.LastName}&email=${auth?.user?.email ?? ""}&a1=&a2=${auth.user?.EmployerName
        }`,
        "_blank"
    );
    localStorage.removeItem('baseURl')
};

export const getBaseURL = () => {
    let baseURl = localStorage.getItem("baseURl") ?? EMPLOYEE_APP_DEFAULT_PATH
    const deepURL = getPathFromInternalLink(baseURl)
    if (!isEmpty(deepURL)) {
        if (deepURL === "calendly") {
            navigateToCalendly()
        } else {
            navigateToInternalLink(deepURL)
        }
    }
    if (baseURl === "/employee/events") {
        baseURl = WEBINAR_PATH
        // localStorage.removeItem('baseURl')
    }
    return baseURl
}

export const getThemeScoreStatus = (score: number) => {
    if (score <= 60) {
        return "Requires attention"
    }
    else if (score > 61 && score <= 89) {
        return "Room for improvement"
    }
    else {
        return "On track"
    }
}

export const getThemeScoreProgressStatus = (score: number) => {
    if (score <= 60) {
        return "Poor"
    }
    else if (score >= 61 && score <= 89) {
        return "Good"
    }
    else {
        return "Excellent"
    }
}

export const getTotalScoreStatus = (score: number) => {
    if (score <= 60) {
        return "#FF0000"
    }
    else if (score > 61 && score <= 89) {
        return "#FFBF00"
    }
    else {
        return "#00FF00"
    }
}

export const getTotalScoreStatusColor = (score: number) => {
    if (score <= 60) {
        return "#E56180"
    }
    else if (score > 61 && score <= 89) {
        return "#BD73E1"
    }
    else {
        return "#767BDD"
    }
}

export function doesUrlMatchProductPagePattern(url: string): boolean {
    //const pattern = `https://${process.env.REACT_APP_GENERIC_URL}/employee/product/*`
    // Escape special characters in the pattern, except for *
    const pattern = '/employee/product/';

    // Check if the URL starts with the defined pattern
    return url.startsWith(pattern);
}

export const getPlasmicTagName = () => {
    const environemnt = process.env.REACT_APP_ENV
    if (environemnt === 'DEVELOPMENT') {
        return 'development'
    } else if (environemnt === 'STAGING') {
        return 'staging'
    } else if (environemnt === 'PRODUCTION') {
        return 'production'
    }
}

export const getIconBasedOnProductName = (productName: string) => {
    switch (productName) {
        case "Investment Advice":
            return InvestmentAdviceIcon
        case "Snoop":
            return MoneyManagementIcon
        case "Mortgage services":
            return MortgageIcon
        case "Will services":
            return WillServiceIcon
        case "Retirement Wealth Management":
            return RetirementBenefitIcon
        case "Protection & Insurance":
            return ProtectionInsuranceIcon        
        default:
            return SavingsInvestmentIcon
    }
}