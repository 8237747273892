import { createApi } from "@reduxjs/toolkit/query/react";
import { isEmpty } from "lodash";
import { ApiConstant } from "../../constants/ApiConstants";
import {
  GetAllProfileInfo,
  ManageProfileData,
  ProfileInfo,
  UpdateProfileData,
  InvestmentAdviceResponse
} from "../../types/profile";
import baseQuery from "./baseQuery";

export const PROFILE_API_REDUCER_KEY = "profileApi";

export const profileApi = createApi({
  reducerPath: PROFILE_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Profile"],
  endpoints: (builder) => ({
    getProfileById: builder.query<ManageProfileData, Partial<ProfileInfo>>({
      query: (body: ProfileInfo) => {
        const { EmployerID, EmployeeID, RoleID } = body;
        return {
          url: ApiConstant.GET_BY_ID_PROFILE,
          method: "POST",
          body: { EmployerID, EmployeeID, RoleID },
        };
      },
      providesTags: ["Profile"],
    }),
    revokeNudge: builder.mutation<
      UpdateProfileData,
      Partial<{ ProductID: string }>
    >({
      query(body) {
        return {
          url: `${ApiConstant.REVOKE_CONSENT}`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (
        response: any
        //   {
        //   data: { error: string; message: string };
        //   status: number;
        // }
      ) => {
        console.log(response);
        return response.data.error;
      },
      
      // invalidatesTags: ["Profile"],
    }),
    editProfile: builder.mutation<
      UpdateProfileData,
      Partial<GetAllProfileInfo>
    >({
      query(body) {
        return {
          url: `${ApiConstant.UPDATE_PROFILE}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0]
        } else {
          return response?.data?.message
        }
      },
      invalidatesTags: ["Profile"],
    }),
    getInvestmentAdvice: builder.query<
      InvestmentAdviceResponse,
      Partial<ProfileInfo>
    >({
      query: (body: ProfileInfo) => {
        const { EmployerID, EmployeeID, RoleID, RedirectionLink } = body;
        return {
          url: ApiConstant.INVESTMENT_ADVICE,
          method: "POST",
          body: { EmployerID, EmployeeID, RoleID, RedirectionLink },
        };
      },
    }),
  }),
});

export const {
  useGetProfileByIdQuery,
  useEditProfileMutation,
  useGetInvestmentAdviceQuery,
  useLazyGetInvestmentAdviceQuery,
  useRevokeNudgeMutation,
} = profileApi;
