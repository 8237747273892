import React, { createContext, useMemo, useState } from "react";
import { PlanComments, PlanDetail, PlanDetails, SchemeDetails } from "types/privatePension";

const PlansContext = createContext<any>({});

export const PlansProvider = ({ children }: { children: React.ReactElement }) => {
  const [isPlanLoading, setPlanLoading] = useState(true);
  const [plans, setPlans] = useState<PlanDetail[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<PlanDetails | null>(null);
  const [scheme, setScheme] = useState<SchemeDetails | null>(null);
  const [comments, setComments] = useState<PlanComments | null>(null);
  
  const memorizedValue = useMemo(
    () => ({
      plans,
      isPlanLoading,
      setPlans,
      setSelectedPlan,
      selectedPlan,
      setComments,
      setScheme,
      scheme,
      comments,
      setPlanLoading,
    }),
    [plans, isPlanLoading, selectedPlan, comments, scheme]);

  return (
    <PlansContext.Provider
      value={memorizedValue}
    >
      {children}
    </PlansContext.Provider>
  );
};

export default PlansContext;
