import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBackButtonService } from './BackButtonService';
import { Button, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const BackButton = () => {
    const { handleManualBackButton } = useBackButtonService();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);

    // Detect screen size for responsiveness
    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 768); // Define breakpoint for mobile
        };

        // Check on mount and whenever the window is resized
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    // Handle the click event for manual back button
    const handleBackClick = () => {
        handleManualBackButton(); // Use service for manual back button handling
    };

    // Only show the back button on mobile devices or small screens
    if (!isMobile) {
        return null; // Don't render the back button on larger screens
    }

    return (
        <IconButton
        onClick={handleBackClick}
        color="primary"

        sx={{
            right: '-9px',
            bottom: '8px',
            borderRadius: '50%',  // Makes the button circular
            backgroundColor: 'transparent',
            padding: '8px',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)', // Adds hover effect
            },
            border: "1px solid rgba(24,17,47,1)",
        }}
    >
        <ArrowBack sx={{ color: "#18112F" }}/>
    </IconButton>
    );
};
export default BackButton;