import { createSlice } from "@reduxjs/toolkit";

type StepperState = {
  submitForm: () => void;
  isValidForm: boolean;
  isMiddleStepCompleted: boolean;
}

const initialState: StepperState = {
  submitForm: () => {},
  isValidForm: false,
  isMiddleStepCompleted: false,
};

const stepper = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    setStepper: (state, action) => {
      const { 
        submitForm,
        isValidForm
      } = action.payload;

      state.submitForm = submitForm;
      state.isValidForm = isValidForm;
    },
    setFormState: (state, action) => {
      state.isValidForm = action.payload;
    },
    setMiddleStepCompleted: (state, action) => {
      state.isMiddleStepCompleted = action.payload;
    },
    resetStepper: (state) => {
      state = initialState;
    },
  },
});

export default stepper.reducer;

export const {
  resetStepper,
  setStepper,
  setFormState,
  setMiddleStepCompleted,
} = stepper.actions;
