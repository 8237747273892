import React, { useEffect } from "react";
import Zendesk from "react-zendesk";

const Chatbtn: React.FC = () => {
  const liveChatTrigger = () => {
    const zendeskFunc = (window as Window & typeof globalThis & { zE: any }).zE;
    zendeskFunc && zendeskFunc("messenger", "show");
    zendeskFunc && zendeskFunc("messenger", "open");
  };

  const hendleAfterLoad = () => {
    const zendeskFunc = (window as Window & typeof globalThis & { zE: any }).zE;
    zendeskFunc && zendeskFunc("messenger", "close");
  };

  useEffect(() => {
    let timeoutId: any;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        liveChatTrigger();
      }, Number((process.env.REACT_APP_INACTIVITY_TIMEOUT)  || 2) * 60 * 60 * 1000);
    };

    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "keypress",
      "scroll",
      "touchstart",
    ];

    events.forEach((event) => {
      document.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, resetTimer);
      });
    };
  }, []);

  return (
    <>
      <Zendesk
        defer
        zendeskKey={process.env.REACT_APP_ZENDESK_KEY as string}
        onLoaded={() => hendleAfterLoad()}
      />
    </>
  );
};

export default Chatbtn;