import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { GuardProps } from "../../types/auth";
import { ADMIN_APP_DEFAULT_PATH, EMPLOYEE_APP_DEFAULT_PATH } from "config";
import Loader from "components/Loader";
import { useSelector } from "store";
import { getAuthInfo } from "selectors/auth";

const PrivateOrgGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user, isLoggingIn, error, isLoggingOut } = useAuth();
  const location = useLocation();
  const auth = useSelector(state => getAuthInfo(state));

  if (isLoggingIn || isLoggingOut) {
    return <Loader />;
  }
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (error) {
    return (
      <Navigate to="/unauthorized-access" state={{ from: location }} replace />
    );
  } else if (user?.RoleID && user?.RoleName && auth.user?.OrganisationType !== 'Private') {
    let defaultPath = EMPLOYEE_APP_DEFAULT_PATH;
    if (user?.RoleName === "Admin") {
      defaultPath = ADMIN_APP_DEFAULT_PATH;
    }

    return <Navigate to={defaultPath} state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateOrgGuard;
