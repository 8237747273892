export const ApiConstant = {
  BASE_URL: process.env.REACT_APP_API_ENDPOINT,
  EMPLOYER_LISTING: "/employer/getAllEmployers",

  //profile
  GET_BY_ID_PROFILE: "/employee/getMyProfile",
  UPDATE_PROFILE: "/employee/updateMyProfile",
  REVOKE_CONSENT: "/nudge/nudgeEmployeeConsentRevoke",

  //employerAdmin profile
  GET_EMP_BY_ID_PROFILE: "/employer/employerByAdmin",
  UPDATE_EMP_PROFILE: "/employer/update/EmployerByAdmin",
  GET_BY_ID_MY_PROFILE: "employee/employeeAdminGetById",
  UPDATE_MY_PROFILE: "employee/employeeByAdminUpdate",
  GET_PENSION_FUNDS_LIST: "masterEntity/pensionFunds/getPensionFundsByEmployee",
  GET_SCHEME_BY_ID: "masterEntity/employerScheme/getSchemeById",
  GET_ALL_ACTIVE_PENSION_FUNDS:
    "/masterEntity/pensionFunds/getAllActivePensionFunds",

  //products
  GET_ALL_PRODUCTS: "/product/getEmployeeProducts",
  ORG_ROLES: "/employee/getOrgRoles",

  //Employee
  EMPLOYEE_LISTING: "/employee/getAllEmployeesByAdmin",
  BULK_IMPORT_EMPLOYEE: "/employee/employeeImportByAdmin",
  INVESTMENT_ADVICE: "/employee/getRoboLink",
  DOWNLOAD_SAMPLE_FILE: "/employee/downloadSampleEmployeeImportFile",
  //NUDGE_CONSENT: "/product/productEmployeeConsent",
  //Dashboard
  DASHBOARD_EMPLOYEE_LISTING: "/employer/orgAdminDashboard",
  DASHBOARD_PRODUCTWISE_EMPLOYEE_LISTING: "/product/employeesByProduct",
  // BULK_IMPORT_EMPLOYEE: "/employee/employeeImportByAdmin",
  // INVESTMENT_ADVICE: "/employee/getRoboLink",
  DOWNLOAD_DASHBOARD_SAMPLE_FILE: "/product/exportEmployeesByProduct",
  SHOW_PRODUCT_INTEREST: "/product/tracking",
  // NUDGE_CONSENT: "/product/productEmployeeConsent"
  NUDGE_CONSENT: "/nudge/nudgeEmployeeConsent",
  NUDGE_STATUS: "/nudge/status",

  DASHBOARD_PRODUCT_USAGE: "/employer/orgAdminDashboardProductUsageStat",
  DASHBOARD_PRODUCT_INTEREST: "/employer/orgAdminDashboardProductInterest",

  //Webinar
  GET_ALL_WEBINAR: "/webinarims/eventGetAll",
  GET_WEBINAR_CHECKAVLIABLITY: "/webinarims/getAttendeeById",
  BOOK_WEBINAR: "webinarims/attendeeCreateMMM",
  CANCEL_WEBINAR: "/webinarims/attendeeUpdateMMM",
  VIEW_MY_BOOKINGS: "/webinarims/bookedEventGetAll",
  GET_WEBINAR_BY_THEME: "/financialHealthCheck/getWebinarListingByThemeId",

  //seminar
  GET_ALL_SEMINAR: "/seminar/eventGetAll",
  GET_SEMINAR_CHECKAVLIABLITY: "/seminar/getAttendeeById",
  BOOK_SEMINAR: "/seminar/attendeeCreateMMM",
  CANCEL_SEMINAR: "/seminar/attendeeUpdateMMM",
  VIEW_MY_SEMINAR_BOOKINGS: "/seminar/bookedEventGetAll",

  //financialHealthCheckup
  GET_FINANCIAL_HEALTH_CHECKUP: "/financialHealthCheck/getAllActiveThemes",
  SUBMIT_QUESTIONARY: "/financialHealthCheck/submitThemeResponse",
  GET_THEME_RESPONSE: "/financialHealthCheck/getThemeResponseScore",
  GET_THEME_EMP_DASHBOARD: "/financialHealthCheck/themeGetEmployeeDashboard",
  GET_ACTIVE_THEMES_WITH_WEBINARS: "/financialHealthCheck/getActiveThemesWithWebinars",
  DOWNLOAD_PRIVACY_DOCUMENT: "/employer/getDocument",

  //privatePension
  VALIDATE_NI_NUMBER: "/privatePension/validateNiNumber",
  VALIDATE_EMPLOYEE_NUMBER: "/privatePension/validateEmployeeNumber",
  CREATE_TEMP_PLAN: "/privatePension/tempPlan",
  GET_TEMP_PLAN: "/privatePension/tempPlan",
  UPDATE_TEMP_PLAN: "/privatePension/tempPlan",
  SUBMIT_TEMP_PLAN: "/privatePension/submitTempPlan",
  AMEND_ACTIVE_PLAN: "/privatePension/amendPlan",
  CANCEL_ACTIVE_PLAN: "/privatePension/cancelPlan",
  GET_PAY_PERIOD_BY_EMPLOYER: "/privatePension/payPeriods",
  GET_PLANS_BY_ORG_ADMIN: "/privatePension/plansByOrgAdmin",
  GET_PLANS_BY_ID: "/privatePension/plan",
  PLAN_COMMENTS: "/privatePension/comment",
  GET_PLAN_STATUS: "/privatePension/planStatus",
  GET_PLAN_NMW_CALCUATION: "/privatePension/nmwCalculation",
  GET_PLAN_REASONS: "/privatePension/planReasons",
  GET_PLAN_DETAILS: "/privatePension/planDetails",
  UPDATE_PLAN_BY_ADMIN: "/privatePension/plan",
  GET_ALL_PLAN_TYPE: "/privatePension/planType",
  GET_USERS_FOR_FILTER: "/privatePension/getUsersforFilter",
  DOWNLOAD_PLANS_ORG : "/privatePension/downloadCSVByOrgAdmin"
};