import { lazy } from "react";
import MainLayout from "../layout/MainLayout";
import Loadable from "../components/Loadable";
import OrganizationAdmin from "pages/organizationAdmin/OrganizationAdmin";
import EmployeeRoleGuard from "utils/route-guard/EmployeeRoleGuard";
import AdminRoleGuard from "utils/route-guard/AdminRoleGuard";
import RoleGuard from "utils/route-guard/RoleGuard";
import UnauthorizeGuard from "utils/route-guard/UnauthorizeGuard";
import DashboardEmployeeList from "../pages/dashboard/DashboardEmployeeList";
import {
  Accessibility,
  Cookies,
  FAQs,
  PrivacyPolicy,
  SharedCostAvcCalculator,
  TermsAndConditions,
  PrivacyPolicyByEmployer,
  ExplainerVideos
} from "pages/cmsPages/PlasmicComponents";
import PermissionGuard from "utils/route-guard/PermissionGuard";
import { Outlet, Navigate } from "react-router";
import BaseRoleGuard from "utils/route-guard/BaseRoleGuard";
import BasePage from "pages/basePage";
import SeminarList from "pages/seminars/SeminarsList";
import SeminarDetail from "pages/seminars/SeminarDetail";
import SeminarBookingsView from "pages/seminars/SeminarBookingsView";
import PrivateOrgGuard from "utils/route-guard/PrivateOrgGuard";

const ProductList = Loadable(
  lazy(() => import("../pages/products/ProductList"))
);

const EmployeeDashboard = Loadable(lazy(() => import("../pages/dashboard/EmployeeDashboard")));

const Profile = Loadable(lazy(() => import("../pages/profile/ViewProfile")));
const OrganizationProfile = Loadable(
  lazy(() => import("../pages/organizationProfile/OrganizationViewProfile"))
);
const MyProfile = Loadable(
  lazy(() => import("../pages/organizationProfile/MyProfileView"))
);

//events
const EventsList = Loadable(lazy(() => import("../pages/events/AllEventList")));
const MyBookings = Loadable(
  lazy(() => import("../pages/events/MyBookingsView"))
);
const EventDetail = Loadable(lazy(() => import("../pages/events/EventDetail")));

const WebinarListbyTheme = Loadable(
  lazy(() => import("../pages/events/GetWebinarByTheme"))
);

const WebinarThemeList = Loadable(
  lazy(() => import("../pages/events/WebinarThemeList"))
);

//dashboard
const Dashboard = Loadable(
  lazy(() => import("../pages/dashboard/DashboardView"))
);

//employees list
const Employees = Loadable(
  lazy(() => import("../pages/dashboard/DashboardEmployeeList"))
);

const PrivatePensionLandingPage = Loadable(
  lazy(() => import("../pages/privatePension/LandingPage"))
);

const PrivatePensionManageMyPlanPage = Loadable(
  lazy(() => import("../pages/privatePension/managePlan/manage-my-plan/ManageMyPlan"))
);

const PrivatePensionNoScheme = Loadable(
  lazy(() => import("../pages/privatePension/managePlan/manage-my-plan/NoScheme"))
);

const PrivatePensionAmendMyPlan = Loadable(
  lazy(() => import("../pages/privatePension/managePlan/amend-my-plan/AmendMyPlan"))
);

const PrivatePensionCancelMyPlan = Loadable(
  lazy(() => import("../pages/privatePension/managePlan/cancel-my-shared-cost-avc/CancelMySharedCostAVC"))
);

const UnAuthorizeAccess = Loadable(
  lazy(() => import("../pages/errorModule/NotAccess"))
);

const LandingPage = Loadable(
  lazy(() => import("../pages/plasmic/LandingPage"))
);

const ApplicationManagement = Loadable(
  lazy(() => import("pages/organizationAdmin/ApplicationManagement"))
)

const ProductPageContainer = Loadable(lazy(() => import("../pages/plasmic/ProductPageContainer")))
//financial health check
const QuestionerList = Loadable(
  lazy(() => import("../pages/financialHealthCheck/ViewQuestioner"))
);

const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));
const queryParams = new URLSearchParams(window.location.search);
const paramsValue = queryParams.get("logout");
const selectedAvcAmount = queryParams.get("avc_amount");
const existingAvcAmount = localStorage.getItem("AvcAmount")
localStorage.setItem("AvcAmount", `${selectedAvcAmount || existingAvcAmount || ''}`);
localStorage.setItem("params", `${paramsValue}`);

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <RoleGuard>
          <Outlet />
        </RoleGuard>
      ),
      children: [
        {
          path: "/",
          element: (
            <BaseRoleGuard>
              <BasePage />
            </BaseRoleGuard>
          ),
        },
        {
          path: "/employee",
          element: (
            <EmployeeRoleGuard>
              <MainLayout />
            </EmployeeRoleGuard>
          ),
          children: [
            // {
            //   path: "products",
            //   element: <ProductList />,
            // },
            {
              path: "products",
              element: <EmployeeDashboard />,
            },
            {
              path: "product/:productName",
              element: <ProductPageContainer />,
            },
            {
              path: "product/:productName/:id",
              element: <LandingPage />,
            },
            {
              path: "homeavc",
              element: <Navigate replace to="/employee/products" />,
            },
            {
              path: "manageavc",
              element: <Navigate replace to="/employee/products" />,
            },
            {
              path: "new-app-form",
              element: <Navigate replace to="/employee/products" />,
            },
            {
              path: "my-investment-advice",
              element: <Navigate replace to="/employee/products" />,
            },
            {
              path: "individualmeeting",
              element: <Navigate replace to="/employee/products" />,
            },
            {
              path: "profile",
              element: <Profile />,
            },
            {
              path: "events/*",
              element: <Navigate replace to="/employee/webinars/theme/list" />,
            },
            {
              path: "privatePension",
              element: (
                <PrivateOrgGuard>
                  <Outlet />
                </PrivateOrgGuard>
              ),
              children: [
                {
                  path: "applicationForm",
                  element: <PrivatePensionLandingPage />,
                },
                {
                  path: "manageAvc",
                  element: <PrivatePensionManageMyPlanPage />,
                },
                {
                  path: "noScheme",
                  element: <PrivatePensionNoScheme />,
                },
                {
                  path: "cancelAvc",
                  element: <PrivatePensionCancelMyPlan />,
                },
                {
                  path: "amendAvc",
                  element: <PrivatePensionAmendMyPlan />,
                },
              ],
            },
            {
              path: "webinars",
              children: [
                {
                  path: "list/:themeName/:id",
                  element: <EventsList />,
                },
                {
                  path: "myBookings",
                  element: <MyBookings />,
                },
                {
                  path: "eventDetail/:id/:themeId?",
                  element: <EventDetail />,
                },
                {
                  path: "theme",
                  children: [
                    {
                      path: "webinar-list/:id",
                      element: <WebinarListbyTheme />,
                    },
                    {
                      path: "list",
                      element: <WebinarThemeList />,
                    },
                  ],
                },
              ],
            },
            {
              path: "seminars",
              children: [
                {
                  path: "myBookings",
                  element: <SeminarBookingsView />,
                },
                {
                  path: "list",
                  element: <SeminarList />,
                },
                {
                  path: "list/:id",
                  element: <SeminarDetail />,
                },
              ],
            },
            {
              path: "cms",
              children: [
                {
                  path: "privacy-policy",
                  element: <PrivacyPolicy />,
                },
                {
                  path: "terms-conditions",
                  element: <TermsAndConditions />,
                },
                {
                  path: "faq",
                  element: <FAQs />,
                },
                {
                  path: "cookies",
                  element: <Cookies />,
                },
                {
                  path: "accessibility",
                  element: <Accessibility />,
                },
                {
                  path: "shared-cost-avc-calculator",
                  element: <SharedCostAvcCalculator />,
                },
                {
                  path: "explainer-videos",
                  element: <ExplainerVideos />,
                },
              ],
            },
            {
              path: "financial-health-check",
              element: (
                <PermissionGuard>
                  <Outlet />
                </PermissionGuard>
              ),
              children: [
                {
                  path: "view",
                  element: <QuestionerList />,
                },
              ],
            },
            {
              path: "privacy-statement",
              element: <PrivacyPolicyByEmployer />,
            },
          ],
        },
        {
          path: "/admin",
          element: (
            <AdminRoleGuard>
              <MainLayout />
            </AdminRoleGuard>
          ),
          children: [
            {
              path: "employee",
              element: <OrganizationAdmin />,
            },
            {
              path: "applicationManagement",
              element: (
                <PrivateOrgGuard>
                  <ApplicationManagement />
                </PrivateOrgGuard>
              ),
            },
            {
              path: "organisationProfile",
              element: <OrganizationProfile />,
            },
            {
              path: "myProfile",
              element: <MyProfile />,
            },
            {
              path: "dashboardEmployeeList",
              element: <DashboardEmployeeList />,
            },
            {
              path: "dashboard",
              element: <Dashboard />,
            },
            {
              path: "employees-list",
              element: <Employees />,
            },
            {
              path: "privacy-statement",
              element: <PrivacyPolicyByEmployer />,
            },
          ],
        },
      ],
    },
    {
      path: "/unauthorized-access",
      element: (
        <UnauthorizeGuard>
          <UnAuthorizeAccess />
        </UnauthorizeGuard>
      ),
    },
    {
      path: "*",
      element: <MaintenanceError />,
    },
  ],
};

export default MainRoutes;
