import { createSlice } from "@reduxjs/toolkit";
import { PrivateTempPlan } from "types/privatePension";

const initialState: PrivateTempPlan = {
  ID: "",
  EmployeeID: "",
  EmployerID: "", 
  AnnualSalary: 0,
  HoursPerWeek: 0,
  PayPeriodID: "",
  IsPercentageSelected: false,
  MaxContributionAmount: 0,
  AvcAmount: 0,
  AvcAmountPercentage: 0,
  StepNumber: 0,
  TermsAcknowledged: false,
  Signature: "",
  CreationReasonID: undefined,
  CreationReasonOther: ""
};

const privateTempPlan = createSlice({
  name: "privateTempPlan",
  initialState,
  reducers: {
    setTempPlan: (state, action) => {
      const { 
        ID,
        EmployeeID,
        EmployerID,
        AnnualSalary,
        HoursPerWeek,
        PayPeriodID,
        MaxContributionAmount,
        AvcAmount,
        AvcAmountPercentage,
        StepNumber,
        TermsAcknowledged,
        Signature,
        CreationReasonID,
        CreationReasonOther,
        IsPercentageSelected
      } = action.payload;

      state.ID = ID;
      state.EmployeeID = EmployeeID;
      state.EmployerID = EmployerID; 
      state.AnnualSalary = AnnualSalary;
      state.HoursPerWeek = HoursPerWeek;
      state.PayPeriodID = PayPeriodID;
      state.MaxContributionAmount = MaxContributionAmount;
      state.AvcAmount = AvcAmount;
      state.IsPercentageSelected = IsPercentageSelected;
      state.AvcAmountPercentage = AvcAmountPercentage;
      state.StepNumber = StepNumber;
      state.TermsAcknowledged = TermsAcknowledged;
      state.Signature = Signature;
      state.CreationReasonID = CreationReasonID;
      state.CreationReasonOther = CreationReasonOther;
    },
    setContibutionAmount: (state, action) => {
      const { AnnualSalary, HoursPerWeek, AvcAmount, PayPeriodID, StepNumber, IsPercentageSelected, AvcAmountPercentage } = action.payload || {}
      state.AnnualSalary = AnnualSalary;
      state.HoursPerWeek = HoursPerWeek;
      state.AvcAmount = AvcAmount
      state.PayPeriodID = PayPeriodID;
      state.StepNumber = StepNumber;
      state.IsPercentageSelected = IsPercentageSelected;
      state.AvcAmountPercentage = AvcAmountPercentage;
    },
    setStepNumber: (state, action) => {
      const { StepNumber } = action.payload || {}
      state.StepNumber = StepNumber;
    },
    resetTempPlan: (state) => {
      state = initialState;
    },
  },
});

export default privateTempPlan.reducer;

export const {
  resetTempPlan,
  setTempPlan,
  setContibutionAmount,
} = privateTempPlan.actions;
